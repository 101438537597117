<template>
  <div>
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />
    <Device
      :config="config"
      @updateStatus="updateStatus"
      :assingDevices="assingDevices"
      :is-parent-loading="parentLoading"
    />
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Device from "@/components/DeviceAssignment/Index.vue";
import router from "@/router";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    Device,
    Breadcrumb,
    ToastificationContent,
  },
  setup(_, context) {
    if (!router.currentRoute.params.id) {
      router.push({ name: "omniview_reseller" });
      return;
    }

    const toast = useToast();
    const parentLoading = ref(false);

    const updateStatus = async (data) => {
      parentLoading.value = true
      if (data.multiple) {
        if(!data.ids){ //if the component send the ids that want to assign, if the component do no send the ids we get all the ids depending on the filters
          const response = await store.dispatch("camera_proxy/getAll", { //get all cameraProxyId Devices. 
            q: data.searchQuery,
            // max: data.perPage,
            //"page[n]": currentPage.value,
            // sortBy: data.sortBy,
            id_unit: data.id_unit,
            id_sector: data.id_sector,
            id_center: data.id_center,
            online: data.online,
            id_tenant: data.id_tenant,
            action: 'justIds',
            omniview_reseller: router.currentRoute.params.id
          });
          data.ids = response.data.cameraProxyIds
        }
        console.log(data.ids)
        //function to assign
        let params = {
          id_omniview_reseller: router.currentRoute.params.id,
          cameraProxyIds: data.ids,
          status: data.status,
        };
        store
          .dispatch("omniview_reseller/addAll", params)
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            (async () => {
              try {
                await store.dispatch("omniview_reseller/getAllDevice", {
                  omniview_reseller: router.currentRoute.params.id,
                });
                 parentLoading.value = false
              } catch (error) {
                console.error("Error executing action:", error);
                 parentLoading.value = false
              }
            })();
          })
          .catch((err) => {
            console.log(err);
             parentLoading.value = false
          });
      } else {
        let params = {
          id_omniview_reseller: router.currentRoute.params.id,
          cameraProxyId: data.id,
          status: data.status,
        };
        store
          .dispatch("omniview_reseller/addOne", params)
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data,
                icon: "CheckIcon",
                variant: "success",
              },
            });

            (async () => {
              try {
                await store.dispatch("omniview_reseller/getAllDevice", {
                  omniview_reseller: router.currentRoute.params.id,
                });
                parentLoading.value = false
              } catch (error) {
                console.error("Error executing action:", error);
                parentLoading.value = false
              }
            })();
          })
          .catch((err) => {
            console.log(err);
            
          });
      }
    };
    const config = {
      id_omniview_reseller: router.currentRoute.params.id,
      filters: {
        tenant: true,
        center: true,
        sector: true,
        units: false,
        online: true,
        assing: true,
      },
    };

    // start breadcrumbs
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("omniview_reseller.device_assignment");
    // end breadcrumbs
    const searchQuery = ref("");
    const assingDevices = computed(() => store.getters["omniview_reseller/getItems"]);

    return {
      updateStatus,
      assingDevices,
      searchQuery,
      config,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      parentLoading
    };
  },
};
</script>
